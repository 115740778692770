import Image from 'next/image';
import styled from 'styled-components';
import Link from '~/components/Link';
import * as LSTVGlobals from '~/globals';
import breakpoints from '~/globals/breakpoints';
import lostImage from '~/images/404.png';
import Navigator from '~/lib/Navigator';

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  margin: 40px auto;
  transform: rotate(5deg);

  width: 285px;
  height: 232px;

  @media ${breakpoints.laptop} {
    width: 432px;
    height: 350px;
  }
`;

const style = {
  width: '90%',
  margin: '30px auto',
  textAlign: 'center' as const, // annoying TS thing
  fontSize: '2rem',
  fontWeight: LSTVGlobals.FONT_WEIGHT_SEMIBOLD,
  lineHeight: '2rem',
};

const paragraphStyle = {
  color: LSTVGlobals.BUTTON_DOWN_COLOR_PURPLE,
  marginBottom: '10px',
};

const paragraphStyleTwo = {
  color: LSTVGlobals.TEXT_AND_SVG_BLACK,
  fontSize: '1.5rem',
};

const hrefStyle = {
  color: LSTVGlobals.BUTTON_DOWN_COLOR_PURPLE,
};

const MiddleDisplay = styled.div`
  width: 100%;

  @media ${breakpoints.laptop} {
    margin-top: 80px;
  }

  @media ${breakpoints.isMobileOrTablet} {
    position: relative;
    top: 50%;
    width: 95%;
  }
`;

const NotFoundPage = () => (
  <MiddleDisplay>
    <ImageContainer>
      <Image src={lostImage} layout="fill" objectFit="contain" alt={"Someone's lost. Either you or us."} />
    </ImageContainer>
    <div style={style}>
      <p style={paragraphStyle}>Lost? So are we. We can&apos;t find this page.</p>
      <p style={paragraphStyleTwo}>
        A good place to start would be our{' '}
        <Link style={hrefStyle} href={Navigator.home}>
          home page
        </Link>
      </p>
    </div>
  </MiddleDisplay>
);

export default NotFoundPage;
