import NextLink from 'next/link';
import { CSSProperties, ReactNode } from 'react';

type Props = {
  href: string;
  children: ReactNode;
  style?: CSSProperties;
};

/**
 * DEPRECATED! Use LSTVLink instead.
 *
 * Wrap an anchor tag with next/link
 */
const Link = ({ href, children, style, ...props }: Props) => {
  if (!href) {
    console.warn('Cannot create a next link with undefined href');
    return null;
  }

  return (
    <NextLink href={href}>
      <a style={style} {...props}>
        {children}
      </a>
    </NextLink>
  );
};

export default Link;
